import React from "react"
import { Flex, Image } from "theme-ui"
import linkedin from "../images/icons/linkedin.svg"
import facebook from "../images/icons/facebook.svg"
import instagram from "../images/icons/instagram.svg"
import youtube from "../images/icons/youtube.svg"
import adoormore from "../images/icons/adoormore.svg"
import { Link } from "./link"

const SocialLinks = () => (
  <Flex
    sx={{
      alignItems: "center",
      a: {
        mx: 2,
        "&:hover": {
          opacity: 0.8,
        },
        "&:first-child": {
          ml: 0,
        },
        "&:last-child": {
          mr: 0,
        },
      },
    }}
  >
    <Link
      href="https://it.linkedin.com/company/gruppo-ab/"
      target="_blank"
      rel="nofollow noopener"
    >
      <Image src={linkedin} />
    </Link>
    <Link
      href="https://www.facebook.com/GruppoAB/"
      target="_blank"
      rel="nofollow noopener"
    >
      <Image src={facebook} />
    </Link>
    <Link
      href="https://www.instagram.com/gruppoab/"
      target="_blank"
      rel="nofollow noopener"
    >
      <Image src={instagram} />
    </Link>
    <Link
      href="https://www.youtube.com/channel/UCtCSgXMFRZnPORFPH73RuJQ"
      target="_blank"
      rel="nofollow noopener"
    >
      <Image src={youtube} />
    </Link>
    <Link href="https://adoormore.com/" target="_blank" rel="nofollow noopener">
      <Image src={adoormore} width={22} height={22} />
    </Link>
  </Flex>
)

export default SocialLinks
